//$primary_color: #e85a4f;
$primary_color: #3aafa9;
$primary_color_dark: #339994;
$complementary_color: #2b7a78;
$complementary_color_dark: #215e5c;

//$background_color: #eae7dc;
$background_color: #ededed;

$status_cfm_color: #3eaf3a;
$status_cancel_color: #af3a3e;
$status_fac_color: #3a40af;
$status_facrq_color: #713aaf;
$status_facnk_color: #715096;
$status_bi_color: #529650;
$status_rq_color: #af903a;
$status_acfm_color: #d40700;
$status_nk_color: #615550;
$status_opt_color: #af563a;

$status_cfm_color_dark: #193324;
$status_opt_color_dark: #004480;
$status_cancel_color_dark: #740b24;